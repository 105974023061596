import React, {useState, useEffect} from 'react';
import appConfigs from '../../../app.settings';
import {CookiesProvider} from 'react-cookie';

export const appContext = React.createContext();

const AppProvider = props => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [currentIndication, setCurrentIndication] = useState('');
  const [magicshot, setMagicshot] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const [ navOpen, setNavOpen ] = useState(false);

  const updateHeaderHeight = _height => {
    setHeaderHeight(_height);
  };

  const updateCurrentIndication = _indication => {
    setCurrentIndication(_indication);
  };

  const updateMagicshot = _flag => {
    setMagicshot(_flag);
  };

  useEffect(() => {
    appConfigs.siteOptions.HEADER_TYPE === 'fixed'
      ? setTopOffset(headerHeight)
      : setTopOffset(0);
  }, [headerHeight]);
  return (
    <appContext.Provider
      value={{
        appConfigs,
        currentIndication,
        headerHeight,
        magicshot,
        topOffset,
        updateHeaderHeight,
        updateCurrentIndication,
        updateMagicshot,
        navOpen,
        setNavOpen
      }}
    >
      {props.children}
    </appContext.Provider>
  );
};

export default ({element}) => (
  <AppProvider>
    <CookiesProvider>{element}</CookiesProvider>
  </AppProvider>
);
